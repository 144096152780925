export const parseQuery = url => {
  if (!url.search) return null
  var search = url.search.substring(1)
  const parsed = JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  )
  return parsed
}
