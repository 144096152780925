import React, { useEffect, useState, useContext } from "react"
import { navigate, Link } from "gatsby"
import styled from "styled-components"
import CreatePassForm from "../components/createPassForm"
import Layout from "../components/Layout"
import TwoCol from "../components/TwoCol"
import { parseQuery } from "../utils/parseQuery"
import axios from "axios"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"
import { breakpoints, colors } from "../utils/siteVars"

const StyledCreatePasswordPage = styled.div`
  .card {
    background: ${colors.white};
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 4px 4px 12px 4px rgba(0, 0, 0, 0.2);
  }
  ${breakpoints.mobile} {
    .card {
      text-align: center;
      align-items: center;
    }
  }
`

const CreatePasswordPage = ({ location }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const [data, setData] = useState({ password: "", repeatPassword: "" })
  const [tokenChecked, setTokenChecked] = useState(false)
  const [passCreateSuccessful, setPassCreateSuccessful] = useState(false)
  const [fieldsError, setFieldsError] = useState("")

  const handleSubmit = e => {
    e.preventDefault()
    let token = ""
    if (location) {
      const query = parseQuery(location)
      if (query) {
        token = query.a
        checkToken(token)
      } else {
        navigate("/fel")
      }
    }
    if (!token) return
    dispatch({ type: "LOADING", value: true })
    if (data.password !== data.repeatPassword) {
      setFieldsError("Lösenorden stämmer inte överens.")
      dispatch({ type: "LOADING", value: false })
      return
    }
    console.log(data)
    axios
      .post(`${process.env.GATSBY_API}no-auth/create-new-password`, data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(() => {
        console.log("Success")
        dispatch({ type: "LOADING", value: false })
        setPassCreateSuccessful(true)
      })
      .catch(err => {
        console.log(err.response)
        dispatch({ type: "LOADING", value: false })
        setFieldsError("Lösenordet uppfyller inte kraven.")
      })
  }

  const checkToken = token => {
    dispatch({ type: "LOADING", value: true })
    axios
      .post(
        `${process.env.GATSBY_API}no-auth/verify-email-token`,
        {},
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then(data => {
        // console.log(data)
        setTokenChecked(true)
        dispatch({ type: "LOADING", value: false })
      })
      .catch(err => {
        dispatch({ type: "LOADING", value: false })
        console.log(err.response)
        navigate("/fel")
      })
  }

  useEffect(() => {
    if (location) {
      const query = parseQuery(location)
      if (query) {
        const token = query.a
        checkToken(token)
      } else {
        navigate("/fel")
      }
    }
    // eslint-disable-next-line
  }, [location])

  return (
    <Layout>
      {tokenChecked ? (
        <StyledCreatePasswordPage>
          <TwoCol className="content-width">
            <div className="left-content">
              <h2>Skapa lösenord</h2>
              <p className="light">
                Här skapar du ditt lösenord som du använder för att komma åt
                filer. Lösenordet måste uppfylla följande krav:
              </p>
              <ul className="light">
                <li>Minst 12 tecken</li>
                <li>Både stora och små bokstäver</li>
                <li>Minst en siffra</li>
              </ul>
            </div>
            {passCreateSuccessful ? (
              <div className="card">
                <h2>Lösenordet har skapats</h2>
                <p>
                  Gå till <Link to="/">inloggningssidan </Link> för att logga
                  in.
                </p>
              </div>
            ) : (
              <CreatePassForm
                fieldsError={fieldsError}
                data={data}
                setData={setData}
                handleSubmit={handleSubmit}
              />
            )}
          </TwoCol>
        </StyledCreatePasswordPage>
      ) : null}
    </Layout>
  )
}

export default CreatePasswordPage
