import React from "react"
import styled from "styled-components"
import { breakpoints, colors } from "../utils/siteVars"
import Button from "./Button"

const StyledCreatePassForm = styled.form`
  background: ${colors.white};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 4px 4px 12px 4px rgba(0, 0, 0, 0.2);
  input {
    width: 100%;
  }
  p.error-message {
    color: ${colors.red};
  }
  h3 {
    margin: 0;
    margin-bottom: 1rem;
    text-align: center;
    color: ${colors.darkGrey};
  }
  ${breakpoints.mobile} {
    text-align: center;
    align-items: center;
  }
`

const CreatePassForm = ({ data, setData, handleSubmit, fieldsError }) => {
  return (
    <StyledCreatePassForm onSubmit={handleSubmit}>
      <p>Lösenord</p>
      <input
        type="password"
        placeholder="Lösenord"
        name="password"
        value={data.password}
        onChange={e => setData({ ...data, [e.target.name]: e.target.value })}
      />
      {fieldsError ? (
        <p className="error-message m0 mbottom2">{fieldsError}</p>
      ) : null}
      <p>Upprepa lösenord</p>
      <input
        type="password"
        placeholder="Upprepa lösenord"
        name="repeatPassword"
        value={data.repeatPassword}
        onChange={e => setData({ ...data, [e.target.name]: e.target.value })}
      />
      <Button med>Skapa lösenord</Button>
    </StyledCreatePassForm>
  )
}

export default CreatePassForm
